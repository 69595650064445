import {Col, Row} from "antd";
import "./Sponsors.css";

const sponsors = [
    // 1300
    {key: 1301, name: 'claesen', image: '/images/sponsors/claesen.png'},
    {key: 1302, name: 'cateringexpress', image: '/images/sponsors/bistro-halverwege.png'},
    // 1100
    {key: 1101, name: 'zonhoven', image: '/images/sponsors/zonhoven.png'},
    {key: 1102, name: 'cristal', image: '/images/sponsors/cristal.png'},
    {key: 1103, name: 'khb', image: '/images/sponsors/khb.png'},
    {key: 1104, name: 'sbro', image: '/images/sponsors/sbro.jpg'},
    {key: 1105, name: 'ssl', image: '/images/sponsors/ssl.jpg'},
    // 700
    {key: 701, name: 'kbc', image: '/images/sponsors/kbc.jpg'},
    {key: 702, name: 'pollers', image: '/images/sponsors/pollers_meeus.png'},
    {key: 703, name: 'jova', image: '/images/sponsors/jova_construct.jpg'},
    {key: 704, name: 'nano', image: '/images/sponsors/nano.png'},
    // 500
    {key: 501, name: 'befrako', image: '/images/sponsors/befrako.png'},
    {key: 502, name: 'vanoppen', image: '/images/sponsors/vanoppen_geert.jpg'},
    {key: 503, name: 'monx', image: '/images/sponsors/monx.png'},
    {key: 504, name: 'tholen', image: '/images/sponsors/architect_tholen.JPG'},
    {key: 505, name: 'wirix', image: '/images/sponsors/tuinenwirix.jpeg'},
    // 300
    {key: 301, name: 'aveve', image: '/images/sponsors/aveve.png'},
    {key: 302, name: 'cm', image: '/images/sponsors/cm.jpg'},
    {key: 303, name: 'comfortenegery', image: '/images/sponsors/comfortenergy.png'},
    {key: 304, name: 'cuthings', image: '/images/sponsors/cuthings.jpg'},
    {key: 305, name: 'friturie', image: '/images/sponsors/defriturie.jpg'},
    {key: 306, name: 'dendijk', image: '/images/sponsors/dendijk.png'},
    {key: 307, name: 'edco', image: '/images/sponsors/edco.jpg'},
    {key: 308, name: 'directdelivery', image: '/images/sponsors/directdelivery.png'},
    {key: 309, name: 'epi-use', image: '/images/sponsors/epi-use.png'},
    {key: 310, name: 'fraiponts', image: '/images/sponsors/fraiponts.jpg'},
    {key: 311, name: 'gsv', image: '/images/sponsors/gsv.png'},
    {key: 312, name: 'indestege', image: '/images/sponsors/indestege.png'},
    {key: 313, name: 'konings', image: '/images/sponsors/konings.png'},
    {key: 314, name: 'meeus', image: '/images/sponsors/meeus.png'},
    {key: 315, name: 'oben', image: '/images/sponsors/oben.png'},
    {key: 316, name: 'quint', image: '/images/sponsors/quint.jpg'},
    {key: 317, name: 'vijfeyken', image: '/images/sponsors/vijfeyken.jpg'},
    {key: 318, name: 'wbss', image: '/images/sponsors/wbss.png'},
    {key: 319, name: 'zinq', image: '/images/sponsors/zinq.png'},
]

const Sponsors = () => {
    return (
        <Row justify={"center"}>
            {sponsors.map(sponsor => {
                return (
                    <Col key={sponsor.key} lg={2} md={6} xs={8} className={"sponsor"} style={{backgroundImage: `url(${sponsor.image})`}}>
                    </Col>
                );
            })}
        </Row>
    );
};

export default Sponsors;