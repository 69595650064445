import "./HomeV2.css";
import {Col, Row} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useCallback} from "react";
import Tile from "../card/Tile";

const tiles = [
    {key: '102', title: 'FAKKELTOCHT - 18u30', imageUrl: '/images/fakkeltocht.jpg', order: {lg: 1, md: 2, xs: 2}},
    {key: '103', title: 'DOORLOPEND STRAATTHEATER', imageUrl: '/images/straattheater.jpg', order: {lg: 3, md: 3, xs: 3}},
    {key: '104', title: 'PLUGGED - 19u30', imageUrl: '/images/bands/plugged.png', order: {lg: 4, md: 4, xs: 4}},
    {key: '105', title: 'FUNKY \'D\' - 22u', imageUrl: '/images/bands/funkyd.jpg', order: {lg: 5, md: 5, xs: 5}},
    {key: '109', title: 'KINDERDISCO TOT 21u', imageUrl: '/images/kinderdisco.jpg', order: {lg: 9, md: 9, xs: 9}},
    {key: '110', title: 'FUIFTENT VANAF 21u', imageUrl: '/images/fuiftent_2.jpg', order: {lg: 10, md: 10, xs: 10}},
    {key: '111', title: 'GRANDIOOS VUURWERK - 21u45', imageUrl: '/images/vuurwerk.jpg', order: {lg: 11, md: 11, xs: 11}},
]

const HomeV2 = () => {

    const screens = useBreakpoint();

    const getImageWidth = useCallback(() => {
        if (screens?.xs) {
            return 250;
        } else {
            return 600;
        }
    }, [screens])

    const onInfoTileClick = (input) => {}

    const handleClick = (lineUp, activeBand) => {}

    const mainTile = tiles[0]

    return (
        <div className={"background"}>
            <Row gutter={[20,20]} style={{margin: "20px", marginTop: "20px", marginBottom: "20px"}}>
                <Col lg={{span: 8, order: 2}} md={{span: 12, order: 1}} xs={{span: 24, order: 1}} className={"tileWrapper"}>
                    <div className={"tile"}>
                        <div className={"logo"}/>
                    </div>
                </Col>
                {tiles.map(tile => (
                    <Col key={tile.key} lg={{span: 8, order: tile.order.lg}} md={{span: 12, order: tile.order.md}} xs={{span: 24, order: tile.order.xs}}>
                        <Tile title={tile.title} backgroundImage={tile.imageUrl}/>
                    </Col>
                ))}
            </Row>
            <Row>
                <col span={24} className={"divider"}></col>
            </Row>
        </div>
    )
};

export default HomeV2;