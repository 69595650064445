import "./Tile.css"
import PropTypes from "prop-types";

const Tile = ({title, backgroundImage}) => {

    return (
        <div className={"tile-wrapper"} style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className={"tile-header"}>
                {title}
            </div>
            <div className={"tile-content"}></div>
        </div>
    )

}

Tile.propTypes = {
    title: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string,
}

export default Tile;